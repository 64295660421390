<template>
  <div class="subscriber">
    <div class="toppic"><img src="@/assets/img/subscriber/subscriber_top.png" alt=""></div>
    <div class="contant">
      <div class="card">
        <div class="pictop"><img src="@/assets/img/subscriber/subscriber_card1.png" alt=""></div>
        <div class="picbottom"><img src="@/assets/img/subscriber/subscriber_card2.png" alt="">
          <div class="cont">距离活动结束：<van-count-down class="coldtime" :time="coldtime" format="DD 天 HH 时 mm 分 ss 秒" />
          </div>
        </div>
      </div>
      <div class="paylist">
        <div class="payitem" :class="checkindex == index ? 'on' : ''" v-for="(item, index) in itemlist" :key="index"
          @click="setitem(item, index)">
          <div class="cardname">{{ item.title }}</div>
          <div class="cardprice"><img src="@/assets/img/subscriber/rmb_icon.png" alt=""><span>{{ item.price
              }}</span>
          </div>
          <div class="tip">超值推荐</div>
        </div>
      </div>
      <div class="btn">
        <button type="button" @click="toBuyClick"><img src="@/assets/img/subscriber/rmb_icon.png" alt=""><span>{{ price
            }}</span>立即开通</button>
      </div>

      <div class="tipbar"><img src="@/assets/img/subscriber/subscriber_tip.png" alt=""></div>

      <div class="dsctext">
        <div class="tit">活动说明</div>
        <div class="cont" v-html="activeData.content" @click="showtippup">

        </div>
      </div>
    </div>

    <van-popup v-model:show="tipaddr" class="subscribertippup" position="center" :overlay="false">
      <div class="tipaddrbox">
        <div class="tipnr">
          <div class="textbox" v-for="(item, index) in activeData.store_list" :key="index">
            <div class="tit">
              <span>{{ item.name }}</span><img src="@/assets/img/subscriber/guid_icon.png" alt="" @click="toguid(item)">
            </div>
            <div class="text">地址：{{item.address}}</div>
          </div>

        </div>
        <div class="close" @click="closebtn"><img src="@/assets/img/subscriber/close_icon.png" alt=""></div>
      </div>
    </van-popup>

    <div class="liketele" @click="linkClick"><img src="@/assets/img/subscriber/subscriber_icon.png" alt=""></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import API from "@/axios";
import tokenApi from "@/axios/tokenApi";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import wx from 'jweixin-js';

const route = useRoute()
const router = useRouter();

const itemlist = ref([])

const activeData = ref({})

const checkindex = ref(0)

const price = ref("")

const tipaddr = ref(false)

const coldtime = ref(0)

const scope_url = ref('');//微信公众号授权发起地址
// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  'checkJsApi',
];

const appId = ref("")

const code = ref("")

const infoData = ref("")

const sku_id = ref("")

const payData = ref({})

const order_no = ref("")


function showtippup() {
  tipaddr.value = true
}

function closebtn() {
  tipaddr.value = false
}

function setitem(item, index) {
  checkindex.value = index
  price.value = item.price
  sku_id.value = item.id
}

const userToken = ref("")
const share_id = ref("")

function getInfo() {
  tokenApi({
    url: "/api/memberDetail",
    method: "post",
    data: {
      userToken: userToken.value,
      share_id: share_id.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      infoData.value = res.data.data
    
      activeData.value = res.data.data
      price.value = res.data.data.sku_list[0].price
      sku_id.value = res.data.data.sku_list[0].id
      itemlist.value = res.data.data.sku_list

      let end_time = res.data.data.end_time
      let now_time = new Date()
      coldtime.value = new Date(end_time).getTime() - now_time.getTime()
      // console.log(coldtime.value)

      loadWxShare()
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//获取微信配置并初始化微信jssdk
function getWxConfig(getCode = false) {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    if (res.data.status == 200) {
      appId.value = res.data.data.appId;
      scope_url.value = res.data.data.scope_url;

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });
      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)
        Toast.fail({
          message: '微信JSSDK信息验证失败！'
        });
      });

      //是否需要授权登录
      if (getCode) {
        getwxCode();
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//微信授权
function getwxCode() {
  let userToken = window.localStorage.getItem('userToken');
  const hrefUrl = window.location.href.split("#")[0]
  if (!userToken) {
    window.location.href = scope_url.value + '?back=' + encodeURIComponent(hrefUrl);
  }
}
//微信登录-》活动详情
function sendCode() {
  API({
    url: "/web/visitorLogin",
    method: "post",
    data: {
      code: code.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res)
      userToken.value = res.data.data.userToken
      window.localStorage.setItem('userToken', userToken.value);
      getInfo()
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}



//加载分享组件
function loadWxShare() {
  wx.ready(function () {
    let shareTitle = infoData.value.share_title;
    let shareDesc = infoData.value.share_desc;
    let shareLink = infoData.value.share_url;
    let shareImg = infoData.value.share_img;
    //1.4以上用这个分享给朋友
    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    //1.4以上用这个分享到朋友圈
    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })
  });
}

function linkClick() {
  window.location.href = "tel://" + activeData.value.bd_tel;
}

function isWeixin() {
  var WxObj = window.navigator.userAgent.toLowerCase();
  if (WxObj.match(/microMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

function toBuyClick() {
  tokenApi({
    url: "/api/createMemberOrder",
    method: "post",
    data: {
      userToken: userToken.value,
      share_id: share_id.value,
      total_price: price.value,
      sku_id: sku_id.value
    },
  }).then((res) => {
    if (res.data.status == 200) {

      payData.value = res.data.data.jsApiParameters

      if (isWeixin()) {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
          }
        } else {
          onBridgeReady(res.data.data.order_no);
        }
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function onBridgeReady(orderNo) {

  order_no.value = orderNo


  // router.push({
  //         name: "payDonePageTwo",
  //         query: {
  //           order_no: order_no.value
  //         }
  //       });


  //挂在window注意
  window.WeixinJSBridge.invoke('getBrandWCPayRequest',
    {
      "appId": payData.value.appId,   //公众号ID，由商户传入
      "timeStamp": payData.value.timeStamp,   //时间戳，自1970年以来的秒数
      "nonceStr": payData.value.nonceStr,      //随机串
      "package": payData.value.package,
      "signType": payData.value.signType,     //微信签名方式：
      "paySign": payData.value.paySign //微信签名
    },
    function (res) {
      if (res.err_msg === "get_brand_wcpay_request:ok") {
        //支付成功跳转页面
        // window.location = 'http://www.baidu.com?no=' + orderNo

        router.push({
          name: "payDonePageTwo",
          query: {
            order_no: order_no.value
          }
        });

      } else if (res.err_msg === "get_brand_wcpay_request:fail") {
        //支付失败跳转页面
        // window.location = 'http://www.baidu.com'
      }
    }
  );
}

function toguid(item){
  window.location.href = "https://uri.amap.com/marker?position=" + item.lng + "," + item.lat  + "";
  // window.location.href = "https://uri.amap.com/marker?position=114.246884,22.720889"
}

onMounted(() => {
  userToken.value = window.localStorage.getItem('userToken');
  // getInfo()

  if (route.query.code) {
    code.value = route.query.code
  } else {
    // code.value = "081m8EFa1VCVbH0mMvHa16rRjX3m8EFt"
  }

  if (route.query.share_id) {
    share_id.value = route.query.share_id
  } else {
    // share_id.value = "Zwp9aq"
  }

  if (userToken.value) {
    // console.log("有userToken",userToken.value)
    getWxConfig();
    getInfo()
  } else {
    // console.log("无userToken")

    if (code.value) {
      //授权回调后,登录并获取活动详情
      // console.log("有code",code.value)
      getWxConfig();
      sendCode()
    } else {
      //无token无code则开始授权
      getWxConfig(true);
    }
  }
})

</script>

<style lang="less" scoped>
.subscriber {
  height: 100vh;
  position: relative;
}

.toppic {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.contant {
  position: absolute;
  left: 0;
  top: 4.5vh;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.card {
  padding: 0 40px;
  box-sizing: border-box;
  margin-bottom: 120px;

  .pictop {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  .picbottom {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .cont {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 0 6.2%;
      font-weight: 400;
      font-size: 28px;
      line-height: 28px;
      color: #FAE2B4;
      display: flex;
      align-items: center;
    }
  }
}

.paylist {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  .payitem {
    width: 204px;
    height: 196px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.05);
    border-radius: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 0 11px;

    .cardname {
      font-weight: 400;
      font-size: 28px;
      color: #C69771;
      line-height: 28px;
      margin-bottom: 32px;
    }

    .cardprice {
      display: flex;
      align-items: flex-end;

      img {
        width: 32px;
        height: 32px;
        display: block;
        margin-bottom: 3px;
        margin-right: 4px;
      }

      span {
        font-weight: bold;
        font-size: 56px;
        color: #2A2826;
        line-height: 56px;
      }
    }
  }

  .on {
    background: #FBF5ED;
    border: 1px solid #E7C79B;
  }

  .tip {
    position: absolute;
    right: -2px;
    top: -19px;
    width: 120px;
    height: 38px;
    background: linear-gradient(131deg, #F7DAB9 0%, #F4C584 100%);
    border-radius: 0px 19px 0px 19px;
    font-weight: 400;
    font-size: 20px;
    color: #7C4733;
    line-height: 38px;
    text-align: center;

  }
}

.btn {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 620px;
    height: 96px;
    background: linear-gradient(124deg, #FAE2B4 0%, #D5AD84 100%);
    border-radius: 48px;
    font-weight: bold;
    font-size: 32px;
    color: #2A2826;
    line-height: 32px;

    img {
      width: 32px;
      height: 32px;
      display: block;
    }

    span {
      margin: 0 4px;
    }
  }
}

.tipbar {
  width: 100%;
  margin-bottom: 58px;

  img {
    width: 100%;
    display: block;
  }
}

.dsctext {
  padding: 0 48px;
  box-sizing: border-box;

  .tit {
    font-weight: bold;
    font-size: 32px;
    color: #E0BD92;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .cont {
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;

    p {
      font-weight: 400;
      font-size: 28px;
      color: #707070;
      line-height: 40px;
    }

    // .textitem {
    //   display: flex;
    //   margin-bottom: 20px;

    //   .icon {
    //     width: 12px;
    //     height: 12px;
    //     background: #E0BD92;
    //     margin: 14px 20px 14px 0;
    //     border-radius: 50%;
    //   }

    //   .nrtext {
    //     flex: 1;
    //     min-width: 0;
    //     font-weight: 400;
    //     font-size: 28px;
    //     color: #707070;
    //     line-height: 40px;
    //     text-align: justify;
    //   }
    // }

    // .textitem:first-of-type {
    //   .nrtext {
    //     color: #E43232;

    //     span {
    //       text-decoration: underline;
    //     }
    //   }
    // }
  }
}

.subscribertippup {
  background: none;
  padding: 0;
}

.tipaddrbox {
  width: 560px;
  height: 506px;
  background: linear-gradient(217deg, #FFFFFF 0%, #FFEFD1 100%);
  border-radius: 8px;
  border: 2px solid #D7B087;
  position: relative;
  padding: 60px 22px 60px 44px;
  box-sizing: border-box;

  .tipnr {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 22px;
    box-sizing: border-box;

    .textbox {
      margin-bottom: 34px;

      .tit {
        display: flex;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 28px;
          color: #7C4733;
          line-height: 28px;
          margin-right: 10px;
          // flex: 1;
          // min-width: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-bottom: 14px;
        }

        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }

      .text {
        font-weight: 400;
        font-size: 24px;
        color: #2A2826;
        line-height: 32px;
      }
    }

    .textbox:last-of-type {
      margin-bottom: 0;
    }
  }

  .tipnr::-webkit-scrollbar {
    width: 8px;
    height: 0;
    background-color: #D7B087;
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.liketele {
  position: fixed;
  right: 20px;
  width: 108px;
  height: 108px;
  bottom: 100px;
  z-index: 30;

  img {
    width: 100%;
    display: block;
  }
}
</style>